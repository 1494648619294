<template>
	<div class="">
		<div class="tableConTop">
			<el-row>
				<el-col :span="6" class="tableConTopLeft">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<!-- <el-breadcrumb-item :to="{ name: 'PermConfPerm' }"><i class="el-icon-back iconBack"></i>{{$t('i18nn_c4418aa4798740f5')}}</el-breadcrumb-item> -->
						<el-breadcrumb-item>{{$t('i18nn_d02053ea2ba9ff5d')}}</el-breadcrumb-item>
					</el-breadcrumb>
					<!-- <span>{{$t('i18nn_2bbc6b12fbdf9019')}}</span> -->
				</el-col>
				<el-col :span="18" class="tableConTopRig">
					<!-- <el-button type="primary" plain icon="el-icon-upload" size="small" @click="openDioalog(null)">{{$t('i18nn_ff6fd823ffab7e6b')}}</el-button>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null)">{{$t('c0246c55b9cac963')}}</el-button> -->
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- 表单提示 -->
		<!-- <tip-form-msg :formMsgDet="['可添加一个权限组，并为其命名，方便添加管理员时使用','可在标题处全选所有功能或根据功能模块逐一选择操作权限，提交保存后生效']">
		</tip-form-msg> -->
		<div class="" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">
			<el-form ref="form" inline :rules="formRules" :model="form" label-width="100px">
				<el-form-item label="客户：" required>
					<cusSelFuzzy ref="cusSelFuzzy" @changeData="changCus"></cusSelFuzzy>
				</el-form-item>
				<el-form-item label="子用户：" v-show="cusId">
					<subUserSelFuzzy ref="subUserSelFuzzy" @changeData="changSubUser"></subUserSelFuzzy>
				</el-form-item>
				
				<!-- <el-tree :data="treeData" :default-expanded-keys="expandedKeys" :default-checked-keys="checkedKeys"
					:default-expand-all="false" show-checkbox :expand-on-click-node="false" :check-on-click-node="true"
					:check-strictly="true" node-key="id" ref="tree" highlight-current @check="handleCheck"
					:props="defaultProps">
				</el-tree> -->
				
				<el-tree :data="treeData" :default-checked-keys="checkedKeys"
					:default-expand-all="true" show-checkbox :expand-on-click-node="false" :check-on-click-node="true"
					:check-strictly="false" node-key="id" ref="tree" highlight-current @check="handleCheck"
					:props="defaultProps">
				</el-tree>

			</el-form>
			<el-button class="subbtn" type="primary" @click="submitForm('form')">{{$t('ad4345dbaabe1479')}}</el-button>
		</div>
	</div>
</template>
<script>
	import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	import subUserSelFuzzy from '@/components/WarehouseCenter2/components/subUserSelFuzzy.vue';
	export default {

		// name: 'HomeConfFrame',
		// //meta信息seo用
		// metaInfo: {
		//   title: '互易天下-管理后台-基础配置'
		// },
		components: {
			// AccountUserList,
			cusSelFuzzy,
			subUserSelFuzzy
		},
		
		data() {
			return {
				// isShowFormMsg: false,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改
				// selectOption: {
				//   // deptList:[],
				//   menuList: []
				// },
				loading_load: false,
				//
				// checkedMenus:[],
				defaultProps: {
					children: 'subListMenuList',
					label: 'menuName'
				},
				treeData: [],

				// expandedKeys: [],
				checkedKeys: [],
				//弹窗
				// dialogTableVisible: false,
				loading: false,
				
				cusId:"",
				subUserId:"",
				form: {
					// "roleId": null,
					// "roleName": "", //权限组
					// "menuIds": [] //菜单id的数组
				},
				formRules: {
					// roleName: [{
					// 		required: true,
					// 		message: this.$t('i18nn_3f92cfa1112482ad'),
					// 		trigger: 'blur'
					// 	},
					// 	// { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
					// ],


				},
			}
		},
		activated() {
			this.initData()
		},
		//创建时
		created() {
			// this.initData()
		},

		//编译挂载前
		mounted() {

			// console.log("mounted");

		},

		methods: {
			initData() {
				// this.form.roleId = null;
				// this.form.roleName = "";
				// this.form.menuIds = [];
				this.$nextTick(()=>{
					this.$refs.cusSelFuzzy.init("");
					this.cusId = "";
					this.subUserId = "";
					this.$refs.subUserSelFuzzy.init("");
				});
				
				this.checkedKeys = [];
				// this.expandedKeys = [];
				this.treeData = [];
				
				// if(this.subUserId){
				// 	this.getPremissDetData(this.subUserId);
				// } else if(this.cusId){
				// 	this.getPremissDetData(this.cusId);
				// 	this.$nextTick(()=>{
				// 		this.$refs.subUserSelFuzzy.setParentId(this.cusId)
				// 	});
				// }
				//数据
				// let id = this.$route.query.id;
				// if (id) {
				// 	//修改
				// 	// this.dialogFormStatus = 1;
				// 	this.getPremissDetData(id);
				// } else {
				// 	//新增
				// 	// this.dialogFormStatus = 0;
				// }
				//菜单
				// this.getMenuData();
				
				
			},
			
			changCus(data) {
				console.log('changCus', data);
				// this.filterData.cusNo = data.cusNo;
				// this.filterData.parentId = data.userId;
				// this.initData();
				this.checkedKeys = [];
				// this.expandedKeys = [];
				this.treeData = [];
				
				this.cusId = data.userId;
				
				this.getPremissDetData(this.cusId);
				this.$nextTick(()=>{
					this.$refs.subUserSelFuzzy.setParentId(this.cusId)
				});
			},
			
			changSubUser(data) {
				console.log('changSubUser', data);
				// this.checkedKeys = [];
				this.checkedKeys = [];
				// this.expandedKeys = [];
				this.treeData = [];
				this.subUserId = data.userId;
				this.getPremissDetData(this.subUserId);
			},
			
			//数组去重‘
			// getArray(a) {
			//  var hash = {},
			//      len = a.length,
			//      result = [];

			//  for (var i = 0; i < len; i++){
			//      if (!hash[a[i]]){
			//          hash[a[i]] = true;
			//          result.push(a[i]);
			//      } 
			//  }
			//  return result;
			// },
			//删除数组中存在的另一个数组
			//  bouncer(arr,un) {
			//     // var un = [false,null,0,"",undefined,NaN];
			//     for(var i = 0; i < arr.length; i++){
			//         for(var j = 0; j < un.length; j++){
			//             if(arr[i] == un[j]){
			//                 arr.splice(i,1);
			//             }
			//         }
			//     }
			//     return arr;
			// },
			//判断数组中是否存在某值
			// hasVal(arr,val){
			//   for(var i = 0; i < arr.length; i++){
			//        if(arr[i] == val ){
			//         return true;
			//        } 
			//     }
			//     return false;
			// },
			//节点选择值改变
			handleCheckChange(data, checked, indeterminate) {
				console.log(data, checked, indeterminate);
			},
			//节点选择
			handleCheck(data, checkedNode) {
				console.log(data, checkedNode);
				//一级菜单
				// if ("0" == data.parentId) {
				// 	//是否选中
				// 	let isChecked = checkedNode.checkedKeys.includes(data.id);
				// 	console.log('isChecked', isChecked);
				// 	//子节点IDs
				// 	let childrenIds = data.subListMenuList.map(item => {
				// 		return item.id
				// 	});
				// 	childrenIds.push(data.id);
				// 	if (isChecked) { //选中
				// 		//全部选中下面子节点
				// 		// this.$refs.tree.setCheckedKeys(childrenIds,false);
				// 		childrenIds.forEach(item => {
				// 			this.$refs.tree.setChecked(item, true, true);
				// 		});
				// 		// this.$refs.tree.setChecked(data.menuId);
				// 	} else { //取消选中
				// 		//全部取消选中下面子节点
				// 		// this.$refs.tree.setCheckedKeys(childrenIds,true);
				// 		// this.$refs.tree.setChecked(data.menuId);
				// 		childrenIds.forEach(item => {
				// 			this.$refs.tree.setChecked(item, false, true);
				// 		});
				// 	}
				// }
			},

			//菜单选择
			// handleCheckedChange(value,item,type) {
			//   console.log(value);
			//   console.log('item',item);
			//   let menuIds = this.form.menuIds;
			//   if(item && item.menuId){
			//     if(1==type){//点击父节点
			//       if(item.menuDtoList && item.menuDtoList.length>0){//存在子节点
			//         if(this.hasVal(value,item.menuId)){//选中
			//           console.log('选中');
			//           let ar = [];
			//           for (var i = 0,ln = item.menuDtoList.length; i <ln; i++) {
			//             menuIds.push(item.menuDtoList[i].menuId);
			//           }
			//         } else {//取消选中
			//           console.log('取消选中');
			//           let childrMenuId = [];
			//           for (var i = 0,ln = item.menuDtoList.length; i <ln; i++) {
			//             childrMenuId.push(item.menuDtoList[i].menuId);
			//           }
			//           menuIds = this.bouncer(menuIds,childrMenuId);
			//         }
			//       }
			//     } else {//点击子节点
			//       menuIds.push(item.menuId);
			//     }
			//   }
			//   this.form.menuIds = this.getArray(menuIds);


			// },

			//提交信息
			submitForm(formName) {
				this.$nextTick(() => {
					this.$refs[formName].validate((valid) => {

						// if (true) {
						if (valid) {
							// alert('submit!');
							// let formData = Object.assign({}, this.form);
							// let formData = [];
							// formData.menuIds = this.checkedKeys;

							let menuIds = this.$refs.tree.getCheckedKeys();
							
							this.treeData.forEach(item => {
								//展开
								// expandedKeys.push(item.id);
								//把勾选的值设置为显示
								if(menuIds.includes(item.id)){
									// menuIds.push(item.id);
									item.isShow = '1';
								} else {
									item.isShow = '0';
								}
								item.subListMenuList.forEach(item2=>{
									// if('0'==item2.isShow){
									// 	menuIds.push(item2.id);
									// }
									if(menuIds.includes(item2.id)){
										// menuIds.push(item.id);
										item.isShow = '1';
										item2.isShow = '1';
									} else {
										item2.isShow = '0';
									}
								});
							});
							
							let formData = this.treeData;
							
							// console.log('formData', formData);
							// return;
							// formData.menuIds = this.checkedMenus;
							//浅拷贝、对象属性的合并
							// this.form = Object.assign({},form);
							// if (0 === this.dialogFormStatus) {
								// formData.roleId = "";
								let userId = this.subUserId;
								if(!this.subUserId){
									userId = this.cusId;
								}
								this.postData(this.$urlConfig.WhSubUserMenusEdit+"/"+userId, formData);
							// } else {
							// 	this.postData(this.$urlConfig.HyPermissGropUpdate, formData);
							// }

						} else {
							console.log('error submit!!');
							this.$alert('提交有误,请检查提交数据！', this.$t('cc62f4bf31d661e3'), {
								type: 'warning',
								confirmButtonText: this.$t('204ffab8a6e01870'),
							});
							return false;
						}
					});
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				this[formName] = {};
				// console.log(this.$refs[formName]);
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					// this.form.remark = ""; //  备注//非必填
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
			},
			//提交信息
			postData(url, formData) {
				let _this = this;
				this.loading = true;
				this.$http.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						this.loading = false;
						if (200 == data.code) {

							this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
								type: 'success',
								// confirmButtonText: this.$t('204ffab8a6e01870'),
							});
							// this.$router.push({
							// 	name: 'PermConfPerm'
							// });
						} else {
							this.$alert(data.msg ? data.msg : "提交失败,请重试", this.$t('cc62f4bf31d661e3'), {
								type: 'warning',
								// confirmButtonText: this.$t('204ffab8a6e01870'),
							});
						}
					})
					.catch((error) => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert('提交失败,请重试！', this.$t('cc62f4bf31d661e3'), {
							type: 'warning',
							// confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					});
			},


			// 查询菜单接口
			// getMenuData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	this.loading_load = true;
			// 	this.$http.get(this.$urlConfig.WhClientMenuAll, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log("查询菜单接口，请求成功");
			// 			console.log(data);
			// 			this.loading_load = false;
			// 			if (200 == data.code) {
			// 				this.treeData = data.rows;
			// 				//展开项，非展开项不可选择
			// 				let expandedKeys = [];
			// 				this.treeData.forEach(item => {
			// 					// if (item.request.indexOf('WarehouseCenter') > -1) { //一级菜单,是仓库菜单
			// 						expandedKeys.push(item.id);
			// 					// } else {
			// 					// 	item.disabled = true; //一级菜单
			// 					// 	item.menuDtoList.forEach(item2 => { //二级菜单
			// 					// 		item2.disabled = true;
			// 					// 	})
			// 					// }
			// 				})
			// 				this.expandedKeys = expandedKeys;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : "查询菜单接口失败,请重试");
			// 			}
			// 		})
			// 		.catch((error) => {
			// 			console.log(error);
			// 			this.loading_load = false;
			// 			console.log("查询菜单接口，请求失败");

			// 			this.$message.error('查询菜单接口失败,请重试！');
			// 		});
			// },

			//查询详情接口
			getPremissDetData(userId) {
				// let _this = this;
				this.loading = true;
				this.$http.get(this.$urlConfig.WhSubUserMenusQuery+"/"+userId, {
						// "roleId": id,
					})
					.then(({
						data
					}) => {
						console.log("查询菜单，请求成功");
						console.log(data);
						
						this.loading = false;
						if (200 == data.code && data.rows) {
							//菜单
							this.treeData = data.rows;
							//展开项，非展开项不可选择
							// let expandedKeys = [];
							//勾选
							let menuIds = [];
							this.treeData.forEach(item => {
								//展开
								// expandedKeys.push(item.id);
								//勾选,显示的值
								// if('1'==item.isShow){
								// 	menuIds.push(item.id);
								// }
								item.subListMenuList.forEach(item2=>{
									if('1'==item2.isShow){
										menuIds.push(item2.id);
									}
								});
							});
							// this.expandedKeys = expandedKeys;
							
							// let menus = data.rows;
							// form.enable = form.enable === '1' ? true : false;
							//浅拷贝、对象属性的合并
							// this.form = Object.assign({}, form);
							//勾选
							// let menuIds = [];
							
							// this.treeData.forEach(item=>{
							// 	menuIds.push(item.id);
							// 	item.subListMenuList.forEach(item2=>{
							// 		menuIds.push(item2.id);
							// 	})
							// })

							this.checkedKeys = menuIds;
							//表格显示数据
							// this.tableData = data.rows;
							//当前数据总条数
							// this.pagination.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							this.$message.success('子用户菜单配置，请求成功');
						} else {
							// if (!data.msg) {
							//   data.msg = "查询用户详情失败,请重试";
							// }
							// this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
							//   type: 'warning',
							//   confirmButtonText: this.$t('204ffab8a6e01870'),
							// });
							this.$message.warning(data.msg ? data.msg : "查询菜单失败,请重试");
						}

					})
					.catch((error) => {
						console.log(error);
						console.log("查询菜单失败，请求失败");
						this.$message.error('查询菜单失败，请求失败！');
						this.loading = false;
					});
			},


		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
